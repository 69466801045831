import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { NeedsOnboardingGuard } from './onboarding/guards/needs-onboarding.guard';
import { ActiveLanguageGuard, LanguageGuard } from '@teamfoster/sdk/language-ngrx';

const appGuards = [LanguageGuard, ActiveLanguageGuard, CookieSettingsGuard, DictionaryGuard, MenuGuard];

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    pathMatch: 'full',
    canActivate: [...appGuards],
  },
  {
    path: 'tour',
    loadChildren: () => import('./tour/tour.module').then(m => m.TourModule),
    canActivate: [NeedsOnboardingGuard, ...appGuards],
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
    canActivate: [...appGuards],
  },
  {
    path: 'page',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards],
  },
  {
    path: 'styleguide',
    loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule),
    canActivate: [],
  },
  {
    path: 'ar',
    loadChildren: () => import('./augmented-reality/augmented-reality.module').then(m => m.AugmentedRealityModule),
    canActivate: [...appGuards],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
