<div class="card onboarding-slider">
  <ngx-glide
    #NgxGlideComponent
    [listenToEvents]="true"
    [showArrows]="false"
    [rewind]="false"
    [showBullets]="true"
    [gap]="30"
    [perView]="1"
    (ranBefore)="onSwipeEnd($event)"
    (moved)="updatePage()"
  >
    <app-onboarding-card class="onboarding-slider__item" *ngFor="let slide of slides" [onboardingSlide]="slide"></app-onboarding-card>
  </ngx-glide>

  <button class="button button--primary" (click)="close()">
    <span>Sla onboarding over</span>
  </button>
</div>
