import { Component, OnInit, ChangeDetectionStrategy, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Language, SetActiveLanguage } from '@teamfoster/sdk/language-ngrx';

@Component({
  selector: 'app-lang-switcher',
  styleUrl: './lang-switcher.component.scss',
  templateUrl: './lang-switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangSwitcherComponent {
  @ViewChild('dropdownpanel') dropdownpanel?: ElementRef;
  @Input() languages: Language[] = [];
  @Input() activeLang?: string;
  dropdownOpen = false;

  constructor(private store: Store) {}

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: Event): void {
    if (!this.dropdownpanel?.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }

  setLanguage(lang: Language) {
    this.store.dispatch(SetActiveLanguage({ activeLang: lang.code }));
  }

  getFlag(code: string) {
    return `/assets/img/country-flags/${code.split('-')?.[1]?.toLowerCase()}.svg`;
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  get activeLanguage(): Language {
    return this.languages.find(a => a.code === this.activeLang) ?? this.languages[0];
  }
}
