import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Html5PlayerVars } from '@teamfoster/sdk/html5-video-player';
import { Image } from '@teamfoster/sdk/image';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() image?: Image;
  @Input() title: string = '';
  @Input() cssClass: string = '';

  @Output() inView = new EventEmitter<any>();
  inviewPort = false;

  constructor() {}

  inViewChange(e: boolean) {
    this.inviewPort = e;
    if (e) {
      this.inView.emit(null);
    }
  }
}
