import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgxGlideComponent } from 'ngx-glide';
import { Router } from '@angular/router';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { OnboardingSlide } from '../../models/onboarding.interface';

@Component({
  selector: 'app-onboarding-slider',
  templateUrl: './onboarding-slider.component.html',
  styleUrls: ['./onboarding-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingSliderComponent implements OnChanges {
  @ViewChild(NgxGlideComponent, { static: false }) ngxGlide!: NgxGlideComponent;
  @Input() slides: OnboardingSlide[] = [];
  @Input() activeIndex: number = 0;

  @Output() finish = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>(); // when going back when you are at index 0

  currentPage = this.activeIndex || 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeIndex'] && changes['activeIndex'].currentValue !== this.currentPage) {
      this.currentPage = this.activeIndex;
      this.goTo(this.currentPage);
    }
  }

  updatePage() {
    this.currentPage = this.ngxGlide.getIndex();
    localStorage.setItem('ONBOARDING_INDEX', this.currentPage.toString());
  }

  goTo(i: number) {
    this.ngxGlide.go(`={${i}}`);
  }

  next() {
    if (this.ngxGlide.getIndex() >= 4) {
      this.finish.emit();
      return;
    }
    this.ngxGlide.go('>');
  }

  close() {
    this.finish.emit();
  }

  previous() {
    if (this.ngxGlide.getIndex() <= 0) {
      this.back.emit();
      return;
    }
    this.ngxGlide.go('<');
  }

  onSwipeEnd(e: any) {
    if (this.ngxGlide.getIndex() + 1 === this.slides.length && e.direction === '>') {
      this.finish.emit();
    }
  }
}
