import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { Observable, combineLatest } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import { Go, State } from '../../../store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { OnboardingSlide } from '../../models/onboarding.interface';
import { getOrderedOnboardingSlides } from '../../store';
import { getActiveLanguage, getSwitchableLanguages } from '@teamfoster/sdk/language-ngrx';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingComponent implements OnInit {
  private store = inject(Store);

  languages$ = this.store.select(getSwitchableLanguages);
  activeLanguage$ = this.store.select(getActiveLanguage);

  cookiePrefs$ = this.store.select(getCookiePreferences);
  slides$ = this.store.select(getOrderedOnboardingSlides);

  prevSessionIndex: number | null = null;

  constructor() {}

  ngOnInit(): void {
    const index = localStorage.getItem('ONBOARDING_INDEX');
    this.prevSessionIndex = +(index || 0);
  }

  finish() {
    window.localStorage.setItem('ONBOARDING_COMPLETE', '1');
    this.store.dispatch(Go({ path: ['/', 'tour'] }));
  }

  back() {
    this.store.dispatch(Go({ path: ['/'] }));
  }
}
