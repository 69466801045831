<article class="onboarding-card">
  <h1 class="h1 text-center mb-5">{{ onboardingSlide.title }}</h1>

  <figure class="onboarding-card__figure mb-2">
    <img [src]="onboardingSlide.img | resize : 900" *ngIf="onboardingSlide.img" />
  </figure>
  <main class="onboarding-card__main">
    <p class="text-primary">{{ onboardingSlide.text }}</p>
  </main>
</article>
