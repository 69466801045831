import { Component, OnInit, Inject, PLATFORM_ID, Renderer2, InjectionToken, inject } from '@angular/core';

import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart } from '@angular/router';
import { Store } from '@ngrx/store';
import { getDictionaryLoaded } from '@teamfoster/sdk/dictionary-ngrx';
import { BehaviorSubject, combineLatest } from 'rxjs';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { tap, withLatestFrom, map } from 'rxjs/operators';
import { routerFade } from './animations';
import { ChangeDetectionStrategy } from '@angular/core';
import { CookieConfig, CookieConfigService, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { getRouterState } from './store';
import { getMenuLoaded } from '@teamfoster/sdk/menu-ngrx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routerFade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private store = inject(Store);

  loading$ = new BehaviorSubject<boolean>(true);
  loaded$ = combineLatest([this.store.select(getDictionaryLoaded), this.store.select(getMenuLoaded)]).pipe(
    map(([dictionaryLoaded, menuLoaded]) => dictionaryLoaded && menuLoaded)
  );
  routeState$ = this.store.select(getRouterState);
  menuOpen$ = this.store.select(navSelectors.getMenuOpen).pipe(tap(a => this.updateRoot(a)));
  cookiePrefs$ = this.store.select(getCookiePreferences);

  domainName: string = '';
  testGridEnabled = false;
  routerAnimationState = '';

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    private router: Router,
    private renderer: Renderer2,
    @Inject(CookieConfigService) private cookieConfig: CookieConfig
  ) {}

  ngOnInit(): void {
    console.log('init');

    // Overlay handlers
    this.router.events.pipe(withLatestFrom(this.cookiePrefs$, this.routeState$)).subscribe(([event, cookies, state]) => {
      if (event instanceof NavigationStart) {
        this.loading$.next(true);
      } else if (event instanceof NavigationEnd) {
        this.loading$.next(false);
      }

      if (
        isPlatformBrowser(this.platformId) &&
        event instanceof NavigationStart &&
        (event as NavigationStart).navigationTrigger === 'imperative'
      ) {
        if (!state?.state?.queryParams) {
          window.scrollTo(0, 0);
        }
      }

      if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd && cookies?.analytical) {
        if ((<any>window).gtag) {
          (<any>window).gtag('config', this.cookieConfig.analyticsCode, { page_path: event.urlAfterRedirects, anonymize_ip: true });
        }
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector('body').classList.add('set--in');
      this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }
  }

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  private updateRoot(menuActive: boolean) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }
}
