import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-404',
  template: `
    <article class="error-404 section-inset-y">
      <div class="container text-center">
        <h1>{{ 'error-404-title' | fromDictionary }}</h1>
        <p class="text-max-width mx-auto">{{ 'error-404-text' | fromDictionary }}</p>
        <div class="error-404__content text-center mt-4">
          <a [routerLink]="'/'" class="button button--primary">Terug naar home</a>
        </div>
      </div>
    </article>
  `,
  standalone: true,
  imports: [RouterLink, DictionaryNgrxModule],
})
export class NotFoundComponent {
  private router = inject(Router);
  code = 404;

  constructor() {
    const statusCodeFromUrl = parseInt(this.router.url.replace(/\//g, ''));
    this.code = statusCodeFromUrl ? statusCodeFromUrl : 404;
  }
}
