<section class="onboarding section-inset-y">
  <div class="container text-center">
    <ng-container *ngIf="languages$ | async as languages">
      <app-lang-switcher
        *ngIf="languages.length"
        language-bar
        [languages]="languages"
        [activeLang]="(activeLanguage$ | async) ?? ''"
        class="d-block mb-3"
      ></app-lang-switcher>
    </ng-container>
  </div>
  <div class="onboarding__wrapper py-5 container" *ngIf="prevSessionIndex !== null">
    <app-onboarding-slider
      *ngIf="slides$ | async as slides"
      [activeIndex]="prevSessionIndex"
      [slides]="slides"
      (finish)="finish()"
      (back)="back()"
    ></app-onboarding-slider>
    <p *ngIf="!(slides$ | async)?.length">Geen onboarding slides gevuld..</p>
  </div>
</section>
